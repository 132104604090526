.dark-mode {
  background-color: #1f1f1f !important;
  border-color: #333 !important;
}

.dark-mode .ant-card-body {
  background-color: #1f1f1f;
}

.dark-mode:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3) !important;
}

.dark-mode-input:hover,
.dark-mode-input:focus {
  border-color: #177ddc !important;
  box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2) !important;
}

/* Responsive styles */
@media (max-width: 768px) {
  .ant-btn {
    width: 100% !important;
    margin: 0.5rem 0 !important;
    font-size: 1rem !important;
  }

  .ant-select {
    width: 80px !important;
  }

  .ant-switch {
    transform: scale(1.2) !important;
  }
  
  .ant-card {
    margin: 0.5rem 0 !important;
  }
  
  h1 {
    font-size: 1.5rem !important;
  }
  
  .ant-card-meta-title {
    font-size: 1rem !important;
  }
  
  .controls {
    flex-direction: column;
    padding: 1rem 0.5rem;
  }
  
  .slider {
    width: 150px !important;
  }
}

/* Dark mode for language selector */
.dark-mode .ant-select-selector {
  background-color: #1f1f1f !important;
  color: white !important;
  border-color: #434343 !important;
}

.dark-mode .ant-card-meta-title {
  color: white !important;
}

.dark-mode .ant-card-meta-description {
  color: #bbb !important;
}

/* Add styles for the new controls */
.ant-slider-track {
  background-color: #1890ff !important;
}

.dark-mode .ant-slider-track {
  background-color: #177ddc !important;
}

.dark-mode .ant-select-selector {
  background-color: #1f1f1f !important;
  border-color: #434343 !important;
}
